import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import "react-accessible-accordion/dist/fancy-example.css";
import { BlogCta } from "./helpdesk-problems-solutions";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/esd_blog_header.png");
const section_1 = require("../../../assets/img/blogs/esd_blog_image_1.png");
const section_2 = require("../../../assets/img/blogs/esd_blog_image_2.png");
const section_3 = require("../../../assets/img/blogs/esd_blog_image_3.png");
const section_4 = require("../../../assets/img/blogs/esd_blog_image_4.png");
const section_5 = require("../../../assets/img/blogs/esd_blog_image_5.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Enterprise Service Desk - Ultimate Guide (2023)"
        description="An enterprise service desk optimizes employee and customer experience. Get chatbot and app workflow automation from Workativ to maximize your service desk efficiency."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt esd_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img text-align-center ${
                  isMobile ? "" : "float-left"
                }`}
              >
                <h1
                  className={`mb-2 ${
                    isMobile
                      ? "font-section-sub-header-blog-title"
                      : "font-section-sub-header-blog-title"
                  }`}
                >
                  Ultimate Guide (2023)
                </h1>
                <h2
                  className={`${
                    isMobile
                      ? "font-page-header-home-blog"
                      : "font-page-header-home-blog"
                  }`}
                >
                  Enterprise Service Desk
                </h2>
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. What is an Enterprise Service Desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How does a service desk at SMB differ from an enterprise
                  service desk for large corporations?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Why is an enterprise service desk a critical business
                  resource?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What are the challenges of an enterprise service desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How does a virtual assistant add value to your enterprise
                  service desk?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. What are the conversational AI platform use cases for
                  enterprise service management?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Why Workativ virtual assistant for your Enterprise Service
                  Desk?
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise service management is a broad niche to maintain, with
                all critical operations tied together across the business
                process. It is not all about taking care of IT infrastructure
                only. Instead, it goes beyond simple IT service management and
                encompasses all critical business areas like HR operations,
                facilities, customers, supply chains, and other departments.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In enterprises, the employee size is enormous, and operation
                processes are complex, so cost-effectiveness is a top priority.
                The downside is not every enterprise leverages sophisticated
                software tools and capabilities to derive real results, i.e.,
                cost efficiency, operational resilience, and efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An enterprise service desk is instrumental in streamlining large
                business operations and offering capabilities to optimize
                employee and customer experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To further define it more clearly, an enterprise service desk is
                an extension of what we know as ITSM with the best practices
                derived from ITIL infrastructure. Just as IT services streamline
                its operations for IT resources, the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/the-service-desk-trends-and-priorities-for-2023">
                  enterprise service desk aims
                </a>{" "}
                at making every work process seamless and more rewarding for
                end-users at every touch point.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Below, we will uncover the best side of an enterprise service
                desk and ways to optimize this tool to unleash ultimate benefits
                that ramp up your operational efficiency and save you operating
                costs.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is an Enterprise Service Desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                According to Forrester Director Charles Betz, “an enterprise
                service desk translates the common service desk into a robust
                system. It helps formalize existing requestable services and
                workflows (IT and non-IT){" "}
                <a href="https://www.forrester.com/blogs/accelerate-with-enterprise-service-management/">
                  through automated workflows built on a low-code or no-code
                  technology platform is an enterprise service desk.”
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, ServiceNow, BMC, Jira, Freshdesk, Ivanti,
                Solarwinds, and more tools offer integrations and capabilities
                to help with incident management with end-to-end ticket
                lifecycle management.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Additionally, the ESM help desk comes with self-service
                functionality to lessen the MTTR time to satisfy both employee
                and customer expectations and demand.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the digital-first ecosystem, holding onto legacy
                infrastructure could mean disarming your help desk's support of
                necessary tools and capabilities and complicating situations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, an{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise">
                  enterprise service desk has one core principle
                </a>{" "}
                to help end users resolve issues efficiently at scale.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does a service desk at SMB differ from an enterprise service
                desk for large corporations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service requests and incident management are more complex for
                enterprises than small and mid-sized businesses. So, a basic
                legacy help desk system would not suffice what enterprises need
                to manage the growing volume and burden of service requests or
                incident tickets.
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Here’s how it differs for SMBs and Enterprises:
              </h4>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Small business has fewer than 100 headcounts with $50 million
                  annual revenue
                </li>
                <li className="font-section-normal-text-testimonials">
                  Mid-sized business consists of employees between 100-999 with
                  annual revenue up to $1 billion
                </li>
                <li className="font-section-normal-text-testimonials">
                  Enterprises have more than 1000 employees and with annual
                  revenue more than 1 billion
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                In terms of employee size and operation levels, IT
                infrastructure and customer queries generally are not as complex
                for SMBs as the enterprise does have.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                From an IT management perspective, SMBs are more concerned about
                day-to-day IT operations than driving business resiliency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In contrast,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  enterprise focuses on IT integration into business objectives
                </a>{" "}
                objectives for long-term business growth.
              </p>

              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                This comparison apart, overall IT budget is massive for
                Enterprises. As per the 2023 State of IT,{" "}
                <a href="https://swzd.com/resources/state-of-it/#soit-2022">
                  64% of enterprises want to increase their IT budgets, whereas
                  only 45% of SMBs express the same interest.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, as enterprises demonstrate responsibilities for customer
                and vendor relationship management, they must have a strict
                business policy to maintain user-centricity and employee
                experience. Rather than a legacy IT infrastructure, they need an
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                  enterprise service desk to transition
                </a>{" "}
                to a modern workplace and expedite operational resilience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the table below, let’s showcase{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  how an enterprise service help desk keeps you ahead
                </a>{" "}
                of challenges brought to the forefront by a legacy system.
              </p>
              <img src={section_2} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Despite the downsides of legacy approaches in service desk, SMBs
                continue to work with traditional systems such as a spreadsheet
                or shared email boxes to track interactions. The reason could be
                that they prioritize internal operations over long-term business
                goals.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However,{" "}
                <a href="https://www.salesforce.com/blog/research-smb-customer-support/">
                  according to a Salesforce blog,
                </a>{" "}
                COVID-19 forced many SMBs to personalize support using digital
                tools such as messaging tools and self-service add-ons.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, enterprises need more sophisticated tools due
                to a broad spectrum of operational models. An{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  enterprise service desk complements its business objectives
                </a>{" "}
                by helping them keep pace with internal and external operational
                efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is an enterprise service desk a critical business resource?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                An enterprise service desk eliminates the need to leverage
                separate tools for organize-wide operations. Instead, it brings
                every process to the same platform, unifies them, and improves
                support delivery for users and employees.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Simplify onboarding for HR operations.
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Extending the ITSM principle that usually is used to resolve a
                common IT-related issue for employees seeking help in device
                provisioning or de-provisioning, HR operations can also follow
                suit.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  leveraging enterprise service desk tools,
                </a>{" "}
                designing an automated workflow to manage employee onboarding is
                easy. Instead of making the employee wait for welcome wishes or
                an organizational catalog to learn about company culture and
                policy, automated HR operations can keep everything ready ahead
                of time.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Allow self-serve for customers.
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                An enterprise help desk seamlessly provides solutions to
                self-serve customer care support. By using knowledge bases,
                FAQs, and chatbots on the web pages, customers seemingly are
                eager to auto-resolve their issues rather than escalate them to
                the agent. Because they know an agent interaction might require
                explaining everything to them repeatedly, which is utterly
                irritating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For internal support, organizations can extend the enterprise
                service desk features and capabilities to help IT support teams.
                With auto-resolution capabilities, teams are more empowered to
                solve their issues and perform at their best.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Maintain workplace governance
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                As you tend to maintain workplace culture, it is mandatory that
                your facilities are safe to work in. An enterprise IT help desk
                can pull up resources for facility managers to seek advice and
                apply it to fix any issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, providing a hygienic place during a pandemic is
                paramount to ensuring employee safety. A regular opt-in health
                survey that allows employees to provide personal health-related
                information can help organizations track and eliminate the risk
                of contamination.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, an IT help desk triggers automated alerts to
                help trace contacts, further allocate resources for sanitation
                work in the facility, and help build a sustainable workplace.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Free agents for critical tasks
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Most often, L1 support takes 2-4 days for agents with manual
                follow-ups, resulting in enormous problems for enterprise
                service desk agents.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The same agent does not always handle the ongoing issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  Manual follow-ups lose context due to lack of data
                </li>
                <li className="font-section-normal-text-testimonials">
                  Call routing to someone less capable can increase backlog
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                It takes just about nothing to create workflows as they use an
                enterprise service desk. These automated workflows help easily
                pull up data and refer to previous conversations while improving
                contextual awareness.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Also, workflows designed to parse complexity levels and pass the
                requests to the most capable agent effectively reduce wait time
                and improve employee and customer experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 en_blog_p_bg">
                <span className="font-section-normal-text-testimonials-medium">
                  {" "}
                  Note:
                </span>{" "}
                An enterprise service desk helps minimize complexities around
                the ESM ecosystem. It depends on IT leaders or CIOs in what way
                they want to use their help desk tools, depending on their
                business use cases.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the challenges of an enterprise service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is little doubt that the enterprise service help desks aim
                to cut costs and improve the service experience. AI-powered
                automation,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  built-in chatbots,
                </a>{" "}
                workflow automation, self-service functionalities, analytics,
                and other{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk">
                  benefits make it a necessary tool for organizations.
                </a>
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However,{" "}
                <span className="font-section-normal-text-testimonials-medium">
                  there are some trailing expenses you need to allocate in your
                  IT budget alongside paying for enterprise service desk tools.
                </span>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Here’s how an enterprise service desk could add more expenses to
                your balance sheet 一
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Self-service functionality comes at a cost -{" "}
                  </span>
                  ITSM platforms such as ServiceNow and others do not offer the
                  capability of self-service in their lower packages. Instead,
                  as an enterprise, if you want to leverage the flexibility of
                  virtual assistants and gain the ability to offer self-service,
                  you need to upgrade to the Professional License package. It is
                  quite an investment for companies amidst the high inflation
                  and recession.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Extended time to deployment -{" "}
                  </span>
                  Most enterprise service desks with core principles of ITSM
                  infrastructure are a complex ecosystem, which is difficult for
                  your in-house team to configure and deploy at scale. It means
                  you need to hire someone with extensive expertise across
                  developer resources to help you design, orchestrate and
                  re-platform your workflows.
                  <br />
                  <span>
                    Not just that, if you need to implement the ESM principle to
                    cover all service areas like HR, Payroll, supply chain,
                    customer service, facilities, and legal, it takes you longer
                    to implement workflows across these areas.{" "}
                  </span>
                  <br />
                  <span>
                    Slower deployment can shoot up costs as your employees still
                    handle tasks manually.
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Slower adoption backfires at the organization-wide mission -
                  </span>
                  the complexity of the device interface hinders faster
                  adoption. Users take time to adapt to the working nature of an
                  enterprise service desk system. The problem gets more
                  complicated when it offers multiple options for one specific
                  action. As a result, the intricacies of the service desk
                  platform cause device abandonment and forces them back to the
                  manual procedures.
                  <br />
                  <span>
                    Another concern is that employees dedicate their time to
                    training and development, impacting productivity and
                    delaying project delivery.
                  </span>
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18">
                With the current scenario forcing CFOs and CIOs to look at ways
                to harness control over costs, optimizing your enterprise
                service desk is a way to satisfy your financial goals.
              </p>
              <BlogCta
                ContentCta="Automate Your Enterprise Service Desk."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Real-time workplace collaboration tools are an answer to
                optimizing your enterprise service desk systems.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With that said,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ virtual assistant
                </a>{" "}
                makes it simple to bring conversational AI flexibility to your
                very own Slack or Microsoft Teams and transform how your teams
                work with a service desk.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does a virtual assistant add value to your enterprise
                service desk?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Adding a virtual assistant to your enterprise service desk
                seamlessly increases communication and collaboration via Slack
                or Microsoft teams across your organization.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                As per Mckinsey,{" "}
                <a href="https://www.mckinsey.com/capabilities/operations/our-insights/digital-collaboration-for-a-connected-manufacturing-workforce">
                  real-time communications can increase productivity by up to
                  30%,
                </a>{" "}
                unlocking $100 billion in value for organizations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In a hybrid workplace, digital collaborations are indeed
                significant in improving coordination. Slack and Microsoft
                service desk bots dedicatedly help you achieve these ambitions.
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                How virtual assistants help you unlock riches
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Self-service functionality with no additional cost -
                  </span>
                  Use a no-code platform to build your virtual assistant or
                  conversational AI platform to deploy to your Slack or MS
                  Teams. Pay only for subscription charges or a custom fee
                  without going for the enterprise license package and spending
                  on external developers.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Faster time to market -
                  </span>
                  Most virtual assistant platforms are no-code. You no longer
                  need to hire developers or spend much time orchestrating your
                  workflows. Use a drag-and-drop feature to create and automate
                  your workflows for ESM across your organization.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Rapid adoption rate -
                  </span>
                  A{" "}
                  <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                    conversational AI platform sits seamlessly inside your
                    business comms channels like Slack or MS Teams.
                  </a>{" "}
                  As your employees are already familiar with these
                  collaborative tools, they do not need additional training to
                  adapt to the enterprise service management ecosystem. All they
                  need is ask a question no matter which department and
                  auto-resolve their issues for repetitive requests.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the conversational AI platform use cases for enterprise
                service management?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Unlike the enterprise service desk, which requires
                back-and-forth logins and outs, a{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  chatbot for Slack or Teams
                </a>
                removes that bottleneck. It accomplishes the same level of
                dexterity in ensuring enterprise service management.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Some critical use cases include 一
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Leave management for HR
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using an automated and intuitive chatbot, it is simple and fast
                to deploy{" "}
                <a href="https://workativ.com/use-cases/leave-management-automation">
                  leave management automation.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="leave management automation in enterprise service desk via chatbot integration"
              />
              <h4 className="font-section-normal-text-testimonials-medium mb-0">
                How it works:
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>User requests for a leave in the chatbot</li>
                <li>
                  Chatbot fetches information from the HRSM tools and allows
                  user to apply for leave
                </li>
                <li>
                  User applies for a leave within a chatbot, and the information
                  escalates to the HRMS tools
                </li>
                <li>HR manager receives email notification</li>
                <li>HR manager approves the leave</li>
                <li>Achieve 5X lower MTTR for repeated service desk issues</li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Password reset for IT
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Password reset is most tiring repetitive and manual tasks for IT
                support. By{" "}
                <a href="https://workativ.com/use-cases/reset-password-automation">
                  automating password reset activity,
                </a>{" "}
                IT teams can free time and focus on the most critical tickets.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="password reset automation via Slack or Teams chatbot for enterprise service desk"
              />
              <h4 className="font-section-normal-text-testimonials-medium mb-0">
                How it works:
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>A user requests in a chatbot to reset her passwords</li>
                <li>A chatbot will ask for an employee email ID</li>
                <li>
                  A pre-built workflow will verify email IDs in the IAM platform
                </li>
                <li>Ask the user to verify OTP via the chatbot </li>
                <li>A user receives an email to reset her password </li>
                <li>
                  The new password will get updated in the IAM application{" "}
                </li>
                <li>
                  Auto-resolution will lead to the closing of the ticket for the
                  password issue all within the chatbot{" "}
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                To increase enterprise service management efficiency in your
                organization, you can efficiently use a conversational AI
                platform and automate the most mundane and repetitive tasks for
                other areas also.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Create app workflows for your chatbot and harness rapid control
                over operations across facilities, legal, and supply chain.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Why Workativ virtual assistant for your Enterprise Service Desk?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ offers a no-code conversational AI platform{" "}
                </a>
                to help you design and orchestrate your app automation workflows
                that seamlessly integrate with any industry-leading enterprise
                service desk ecosystem and transform your workplace experience.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="enterprise service desk integration with chatbot automation"
              />
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, Workativ virtual assistant can offer AI-based
                self-service automation for the following platforms and{" "}
                <a href="https://workativ.com/conversational-ai-platform/integrations">
                  many others 一{" "}
                </a>
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                    ServiceNow chatbot with automation{" "}
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/jira-service-desk-chatbot">
                    Jira Service Desk chatbot with automation{" "}
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/solarwinds-chatbot">
                    {" "}
                    SolarWinds chatbot with automation{" "}
                  </a>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Also, with the Workativ virtual assistant, organizations are
                capable of reducing a potential amount of operating costs. See
                how Workativ helps you maximize your enterprise service desk
                capabilities with chatbot and automation.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Cost savings by up to $21,000 monthly
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Chatbot and automation help you auto-resolve simple to complex
                issues at scale, bringing down costs to $21,000 monthly compared
                to $45,000 a month when chatbot and automation are not used
                together for an enterprise with more than 3,000 employees.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Call volume reduction by 40%
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A conversational AI platform from Workativ helps you reduce
                email and call volumes with instant auto-resolution by 40%.
                Also, it improves first contact resolution by 90%.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                20-30% reduction in labor
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ brings a significant reduction in labor with chatbot
                and app workflow automation in the first year of deployment.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Rapid deployment with multiple options
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                With the Workativ conversational AI platform, it is easier to
                get started with chatbot and app workflow automation. There are
                three options such as,
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>On-premise connector </li>
                <li>API-integration</li>
                <li>Marketplace downloads</li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                To know how you can maximize the benefits of your enterprise
                service desk and optimize operational costs, request a demo
                today with Workativ product experts at{" "}
                <a href="mailto:sales@workativ.com"> sales@workativ.com.</a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. What is an Enterprise Service Desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How does a service desk at SMB differ from an enterprise
                    service desk for large corporations?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Why is an enterprise service desk a critical business
                    resource?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What are the challenges of an enterprise service desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How does a virtual assistant add value to your enterprise
                    service desk?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. What are the conversational AI platform use cases for
                    enterprise service management?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Why Workativ virtual assistant for your Enterprise
                    Service Desk?
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Enterprise service management is a broad niche to maintain, with
                all critical operations tied together across the business
                process. It is not all about taking care of IT infrastructure
                only. Instead, it goes beyond simple IT service management and
                encompasses all critical business areas like HR operations,
                facilities, customers, supply chains, and other departments.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In enterprises, the employee size is enormous, and operation
                processes are complex, so cost-effectiveness is a top priority.
                The downside is not every enterprise leverages sophisticated
                software tools and capabilities to derive real results, i.e.,
                cost efficiency, operational resilience, and efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                An enterprise service desk is instrumental in streamlining large
                business operations and offering capabilities to optimize
                employee and customer experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To further define it more clearly, an enterprise service desk is
                an extension of what we know as ITSM with the best practices
                derived from ITIL infrastructure. Just as IT services streamline
                its operations for IT resources, the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/the-service-desk-trends-and-priorities-for-2023">
                  enterprise service desk aims
                </a>{" "}
                at making every work process seamless and more rewarding for
                end-users at every touch point.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Below, we will uncover the best side of an enterprise service
                desk and ways to optimize this tool to unleash ultimate benefits
                that ramp up your operational efficiency and save you operating
                costs.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                What is an Enterprise Service Desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                According to Forrester Director Charles Betz, “an enterprise
                service desk translates the common service desk into a robust
                system. It helps formalize existing requestable services and
                workflows (IT and non-IT){" "}
                <a href="https://www.forrester.com/blogs/accelerate-with-enterprise-service-management/">
                  through automated workflows built on a low-code or no-code
                  technology platform is an enterprise service desk.”
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, ServiceNow, BMC, Jira, Freshdesk, Ivanti,
                Solarwinds, and more tools offer integrations and capabilities
                to help with incident management with end-to-end ticket
                lifecycle management.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Additionally, the ESM help desk comes with self-service
                functionality to lessen the MTTR time to satisfy both employee
                and customer expectations and demand.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the digital-first ecosystem, holding onto legacy
                infrastructure could mean disarming your help desk's support of
                necessary tools and capabilities and complicating situations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, an{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise">
                  enterprise service desk has one core principle
                </a>{" "}
                to help end users resolve issues efficiently at scale.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does a service desk at SMB differ from an enterprise service
                desk for large corporations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service requests and incident management are more complex for
                enterprises than small and mid-sized businesses. So, a basic
                legacy help desk system would not suffice what enterprises need
                to manage the growing volume and burden of service requests or
                incident tickets.
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                Here’s how it differs for SMBs and Enterprises:
              </h4>
              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Small business has fewer than 100 headcounts with $50 million
                  annual revenue
                </li>
                <li className="font-section-normal-text-testimonials">
                  Mid-sized business consists of employees between 100-999 with
                  annual revenue up to $1 billion
                </li>
                <li className="font-section-normal-text-testimonials">
                  Enterprises have more than 1000 employees and with annual
                  revenue more than 1 billion
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                In terms of employee size and operation levels, IT
                infrastructure and customer queries generally are not as complex
                for SMBs as the enterprise does have.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                From an IT management perspective, SMBs are more concerned about
                day-to-day IT operations than driving business resiliency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In contrast,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/transform-service-desk-with-it-chatbot">
                  enterprise focuses on IT integration into business objectives
                </a>{" "}
                for long-term business growth.
              </p>

              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                This comparison apart, overall IT budget is massive for
                Enterprises. As per the 2023 State of IT,{" "}
                <a href="https://swzd.com/resources/state-of-it/#soit-2022">
                  64% of enterprises want to increase their IT budgets, whereas
                  only 45% of SMBs express the same interest.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, as enterprises demonstrate responsibilities for customer
                and vendor relationship management, they must have a strict
                business policy to maintain user-centricity and employee
                experience. Rather than a legacy IT infrastructure, they need an
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise">
                  enterprise service desk to transition
                </a>{" "}
                to a modern workplace and expedite operational resilience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the table below, let’s showcase{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                  how an enterprise service help desk keeps you ahead
                </a>{" "}
                of challenges brought to the forefront by a legacy system.
              </p>
              <img src={section_2} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                Despite the downsides of legacy approaches in service desk, SMBs
                continue to work with traditional systems such as a spreadsheet
                or shared email boxes to track interactions. The reason could be
                that they prioritize internal operations over long-term business
                goals.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However,{" "}
                <a href="https://www.salesforce.com/blog/research-smb-customer-support/">
                  according to a Salesforce blog,
                </a>{" "}
                COVID-19 forced many SMBs to personalize support using digital
                tools such as messaging tools and self-service add-ons.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, enterprises need more sophisticated tools due
                to a broad spectrum of operational models.{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  enterprise service desk complements its business objectives
                </a>{" "}
                by helping them keep pace with internal and external operational
                efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Why is an enterprise service desk a critical business resource?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                An enterprise service desk eliminates the need to leverage
                separate tools for organize-wide operations. Instead, it brings
                every process to the same platform, unifies them, and improves
                support delivery for users and employees.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Simplify onboarding for HR operations.
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                Extending the ITSM principle that usually is used to resolve a
                common IT-related issue for employees seeking help in device
                provisioning or de-provisioning, HR operations can also follow
                suit.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  leveraging enterprise service desk tools,
                </a>{" "}
                designing an automated workflow to manage employee onboarding is
                easy. Instead of making the employee wait for welcome wishes or
                an organizational catalog to learn about company culture and
                policy, automated HR operations can keep everything ready ahead
                of time.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Allow self-serve for customers.
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                An enterprise help desk seamlessly provides solutions to
                self-serve customer care support. By using knowledge bases,
                FAQs, and chatbots on the web pages, customers seemingly are
                eager to auto-resolve their issues rather than escalate them to
                the agent. Because they know an agent interaction might require
                explaining everything to them repeatedly, which is utterly
                irritating.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For internal support, organizations can extend the enterprise
                service desk features and capabilities to help IT support teams.
                With auto-resolution capabilities, teams are more empowered to
                solve their issues and perform at their best.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Maintain workplace governance
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18">
                As you tend to maintain workplace culture, it is mandatory that
                your facilities are safe to work in. An enterprise IT help desk
                can pull up resources for facility managers to seek advice and
                apply it to fix any issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, providing a hygienic place during a pandemic is
                paramount to ensuring employee safety. A regular opt-in health
                survey that allows employees to provide personal health-related
                information can help organizations track and eliminate the risk
                of contamination.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, an IT help desk triggers automated alerts to
                help trace contacts, further allocate resources for sanitation
                work in the facility, and help build a sustainable workplace.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Free agents for critical tasks
              </h3>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Most often, L1 support takes 2-4 days for agents with manual
                follow-ups, resulting in enormous problems for enterprise
                service desk agents.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  The same agent does not always handle the ongoing issue
                </li>
                <li className="font-section-normal-text-testimonials">
                  Manual follow-ups lose context due to lack of data
                </li>
                <li className="font-section-normal-text-testimonials">
                  Call routing to someone less capable can increase backlog
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                It takes just about nothing to create workflows as they use an
                enterprise service desk. These automated workflows help easily
                pull up data and refer to previous conversations while improving
                contextual awareness.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Also, workflows designed to parse complexity levels and pass the
                requests to the most capable agent effectively reduce wait time
                and improve employee and customer experience.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 en_blog_p_bg">
                <span className="font-section-normal-text-testimonials-medium">
                  {" "}
                  Note:
                </span>{" "}
                An enterprise service desk helps minimize complexities around
                the ESM ecosystem. It depends on IT leaders or CIOs in what way
                they want to use their help desk tools, depending on their
                business use cases.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the challenges of an enterprise service desk?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                There is little doubt that the enterprise service help desks aim
                to cut costs and improve the service experience. AI-powered
                automation,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/service-desk-chatbot-guide">
                  built-in chatbots,
                </a>{" "}
                workflow automation, self-service functionalities, analytics,
                and other{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/benefits-of-ai-powered-service-desk">
                  benefits make it a necessary tool for organizations.
                </a>
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                However,{" "}
                <span className="font-section-normal-text-testimonials-medium">
                  there are some trailing expenses you need to allocate in your
                  IT budget alongside paying for enterprise service desk tools.
                </span>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Here’s how an enterprise service desk could add more expenses to
                your balance sheet 一
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Self-service functionality comes at a cost -{" "}
                  </span>
                  ITSM platforms such as ServiceNow and others do not offer the
                  capability of self-service in their lower packages. Instead,
                  as an enterprise, if you want to leverage the flexibility of
                  virtual assistants and gain the ability to offer self-service,
                  you need to upgrade to the Professional License package. It is
                  quite an investment for companies amidst the high inflation
                  and recession.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Extended time to deployment -{" "}
                  </span>
                  Most enterprise service desks with core principles of ITSM
                  infrastructure are a complex ecosystem, which is difficult for
                  your in-house team to configure and deploy at scale. It means
                  you need to hire someone with extensive expertise across
                  developer resources to help you design, orchestrate and
                  re-platform your workflows.
                  <br />
                  <span>
                    Not just that, if you need to implement the ESM principle to
                    cover all service areas like HR, Payroll, supply chain,
                    customer service, facilities, and legal, it takes you longer
                    to implement workflows across these areas.{" "}
                  </span>
                  <br />
                  <span>
                    Slower deployment can shoot up costs as your employees still
                    handle tasks manually.
                  </span>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Slower adoption backfires at the organization-wide mission -
                  </span>
                  the complexity of the device interface hinders faster
                  adoption. Users take time to adapt to the working nature of an
                  enterprise service desk system. The problem gets more
                  complicated when it offers multiple options for one specific
                  action. As a result, the intricacies of the service desk
                  platform cause device abandonment and forces them back to the
                  manual procedures.
                  <br />
                  <span>
                    Another concern is that employees dedicate their time to
                    training and development, impacting productivity and
                    delaying project delivery.
                  </span>
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18">
                With the current scenario forcing CFOs and CIOs to look at ways
                to harness control over costs, optimizing your enterprise
                service desk is a way to satisfy your financial goals.
              </p>
              <BlogCta
                ContentCta="Automate Your Enterprise Service Desk."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p class="font-section-normal-text-testimonials line-height-18">
                Real-time workplace collaboration tools are an answer to
                optimizing your enterprise service desk systems.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                With that said,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ virtual assistant
                </a>{" "}
                makes it simple to bring conversational AI flexibility to your
                very own Slack or Microsoft Teams and transform how your teams
                work with a service desk.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How does a virtual assistant add value to your enterprise
                service desk?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Adding a virtual assistant to your enterprise service desk
                seamlessly increases communication and collaboration via Slack
                or Microsoft teams across your organization.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                As per Mckinsey,{" "}
                <a href="https://www.mckinsey.com/capabilities/operations/our-insights/digital-collaboration-for-a-connected-manufacturing-workforce">
                  real-time communications can increase productivity by up to
                  30%,
                </a>{" "}
                unlocking $100 billion in value for organizations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                In a hybrid workplace, digital collaborations are indeed
                significant in improving coordination. Slack and Microsoft
                service desk bots dedicatedly help you achieve these ambitions.
              </p>
              <h4 className="font-section-normal-text-medium-customer-banner">
                How virtual assistants help you unlock riches
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Self-service functionality with no additional cost -
                  </span>
                  Use a no-code platform to build your virtual assistant or
                  conversational AI platform to deploy to your Slack or MS
                  Teams. Pay only for subscription charges or a custom fee
                  without going for the enterprise license package and spending
                  on external developers.
                </li>{" "}
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Faster time to market -
                  </span>
                  Most virtual assistant platforms are no-code. You no longer
                  need to hire developers or spend much time orchestrating your
                  workflows. Use a drag-and-drop feature to create and automate
                  your workflows for ESM across your organization.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Rapid adoption rate -
                  </span>
                  A{" "}
                  <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                    conversational AI platform sits seamlessly inside your
                    business comms channels like Slack or MS Teams.
                  </a>{" "}
                  As your employees are already familiar with these
                  collaborative tools, they do not need additional training to
                  adapt to the enterprise service management ecosystem. All they
                  need is ask a question no matter which department and
                  auto-resolve their issues for repetitive requests.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What are the conversational AI platform use cases for enterprise
                service management?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Unlike the enterprise service desk, which requires
                back-and-forth logins and outs, a{" "}
                <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot">
                  chatbot for Slack or Teams
                </a>
                removes that bottleneck. It accomplishes the same level of
                dexterity in ensuring enterprise service management.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Some critical use cases include 一
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Leave management for HR
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using an automated and intuitive chatbot, it is simple and fast
                to deploy{" "}
                <a href="https://workativ.com/use-cases/leave-management-automation">
                  leave management automation.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="leave management automation in enterprise service desk via chatbot integration"
              />
              <h4 className="font-section-normal-text-testimonials-medium mb-0">
                How it works:
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>User requests for a leave in the chatbot</li>
                <li>
                  Chatbot fetches information from the HRSM tools and allows
                  user to apply for leave
                </li>
                <li>
                  User applies for a leave within a chatbot, and the information
                  escalates to the HRMS tools
                </li>
                <li>HR manager receives email notification</li>
                <li>HR manager approves the leave</li>
                <li>Achieve 5X lower MTTR for repeated service desk issues</li>
              </ul>
              <h3 className="font-section-sub-header-small-home ">
                Password reset for IT
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Password reset is most tiring repetitive and manual tasks for IT
                support. By{" "}
                <a href="https://workativ.com/use-cases/reset-password-automation">
                  automating password reset activity,
                </a>{" "}
                IT teams can free time and focus on the most critical tickets.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="password reset automation via Slack or Teams chatbot for enterprise service desk"
              />
              <h4 className="font-section-normal-text-testimonials-medium mb-0">
                How it works:
              </h4>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>A user requests in a chatbot to reset her passwords</li>
                <li>A chatbot will ask for an employee email ID</li>
                <li>
                  A pre-built workflow will verify email IDs in the IAM platform
                </li>
                <li>Ask the user to verify OTP via the chatbot </li>
                <li>A user receives an email to reset her password </li>
                <li>
                  The new password will get updated in the IAM application{" "}
                </li>
                <li>
                  Auto-resolution will lead to the closing of the ticket for the
                  password issue all within the chatbot{" "}
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                To increase enterprise service management efficiency in your
                organization, you can efficiently use a conversational AI
                platform and automate the most mundane and repetitive tasks for
                other areas also.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Create app workflows for your chatbot and harness rapid control
                over operations across facilities, legal, and supply chain.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Why Workativ virtual assistant for your Enterprise Service Desk?
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0 pb-1">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ offers a no-code conversational AI platform{" "}
                </a>
                to help you design and orchestrate your app automation workflows
                that seamlessly integrate with any industry-leading enterprise
                service desk ecosystem and transform your workplace experience.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="enterprise service desk integration with chatbot automation"
              />
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, Workativ virtual assistant can offer AI-based
                self-service automation for the following platforms and{" "}
                <a href="https://workativ.com/conversational-ai-platform/integrations">
                  many others 一{" "}
                </a>
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/servicenow-chatbot">
                    ServiceNow chatbot with automation{" "}
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/jira-service-desk-chatbot">
                    Jira Service Desk chatbot with automation{" "}
                  </a>
                </li>
                <li className="font-section-normal-text-testimonials">
                  <a href="https://workativ.com/conversational-ai-platform/solarwinds-chatbot">
                    {" "}
                    SolarWinds chatbot with automation{" "}
                  </a>
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                Also, with the Workativ virtual assistant, organizations are
                capable of reducing a potential amount of operating costs. See
                how Workativ helps you maximize your enterprise service desk
                capabilities with chatbot and automation.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Cost savings by up to $21,000 monthly
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Chatbot and automation help you auto-resolve simple to complex
                issues at scale, bringing down costs to $21,000 monthly compared
                to $45,000 a month when chatbot and automation are not used
                together for an enterprise with more than 3,000 employees.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Call volume reduction by 40%
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                A conversational AI platform from Workativ helps you reduce
                email and call volumes with instant auto-resolution by 40%.
                Also, it improves first contact resolution by 90%.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                20-30% reduction in labor
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ brings a significant reduction in labor with chatbot
                and app workflow automation in the first year of deployment.
              </p>
              <h3 className="font-section-sub-header-small-home ">
                Rapid deployment with multiple options
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                With the Workativ conversational AI platform, it is easier to
                get started with chatbot and app workflow automation. There are
                three options such as,
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li>On-premise connector </li>
                <li>API-integration</li>
                <li>Marketplace downloads</li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                To know how you can maximize the benefits of your enterprise
                service desk and optimize operational costs, request a demo
                today with Workativ product experts at{" "}
                <a href="mailto:sales@workativ.com"> sales@workativ.com.</a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience"
              className="font-section-normal-text-testimonials"
            >
              Service Desk Chatbot Automation: A Complete Guide
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              ITSM CHATBOT: Use Cases and Benefits Explained
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/service-desk-automation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Service Desk Automation: ELEVATE YOUR IT SUPPORT EFFICIENCY
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
